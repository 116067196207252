import * as React from "react"
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { Alert, AlertTitle } from '@material-ui/lab';

export const AlertQuery = gql`
  {
    alerts {
      id
      severity
      body
      title
    }
  }
`;

export const StupidQuery = gql`
{
	users { sub }
}
`;

const Alerts = () => {

  const stupidResponse = useQuery(StupidQuery);
  console.log(stupidResponse);
  const { loading, error, data } = useQuery(AlertQuery);
  
  

  
  
  return (
    
    <div>
      { error && error.message }
      {data && data.alerts.map((alertdata, i) => {
        return (<Alert onClose={() => {
          //TODO :: Close the alert
          
        }} severity={alertdata.severity}>
          <AlertTitle>{alertdata.title}</AlertTitle>
        {alertdata.body}
        </Alert>);
      })}
    </div>
  )
}

export default Alerts;
