import React from 'React';
import { Link } from 'gatsby';
import { useUser } from '../services/auth/userinfo';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';


const PingMutation = gql`
mutation Ping($sub: ID!, $email: String, $awsidentity: String){
    ping(sub: $sub,
    email: $email,
    awsidentity: $awsidentity) { sub }
  }
`;

export function UserInfo() {
  const userinfo = useUser();
  if (userinfo.user != null) {
    const [ping, { data }] = useMutation(PingMutation);
    ping({ variables: { sub: userinfo.user.sub, email: userinfo.user.email, awsidentity: userinfo.identity}})
    console.log("ui", userinfo);  
  }
  
  return (userinfo ? <Link to="#" onClick={userinfo.logout}>Logout</Link> : <div>Login</div>)
}


export default UserInfo;