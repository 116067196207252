import * as React from "react"
import {Helmet} from "react-helmet";
import Amplify from 'aws-amplify';
import awsconfig from '../aws-exports';
import { withAuthenticator } from '@aws-amplify/ui-react'
import ToolCard from '../components/toolcard';
import Alerts from '../components/alerts';
import { ApolloProvider } from '@apollo/client';
import { client } from '../services/graphql/client'
import { UserProvider } from '../services/auth/userinfo';
import { UserInfo } from '../components/userinfo';

Amplify.configure(awsconfig);




// styles
const pageStyles = {
  color: "#232129",
  padding: 96,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const IndexPage = () => {
  return (
    
    <main style={pageStyles}>
      
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/js/materialize.min.js"></script>        
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
      </Helmet>
      <UserProvider>
      
      <title>Spoonflower Engineering</title>
      <div className="container">
      <ApolloProvider client={client}>
      <Alerts/>
      

      <UserInfo/>
      </ApolloProvider>
        
          
        
        <h1 className="header">Spoonflower Engineering</h1>
       
        <div className="row">
          <ToolCard link="/devops/vpn" name="VPN Setup" description="Help setup my VPN connection"/>
        </div>
      
        
        
    </div>
    </UserProvider>
    </main>
    
    
  )
}

export default withAuthenticator(
  IndexPage
)
