import React from 'react';
import { Auth } from 'aws-amplify';

export const UserContext = React.createContext(null);

/* The UserContext was previously defined */
export const UserProvider = (props) => {  
  const [user, setUser] = React.useState(null);
  const [identity, setIdentity] = React.useState(null);

  const login = (usernameOrEmail, password) =>
   Auth.signIn(usernameOrEmail, password)
     .then(cognitoUser => setUser(cognitoUser));

  const logout = () => 
   Auth.signOut()
     .then(() => setUser(null));

  // fetch the info of the user that may be already logged in
  React.useEffect(() => {   
    Auth.currentAuthenticatedUser()
     .then(user => setUser({
         email: JSON.parse(user.attributes.identities)[0].userId,
         sub: user.attributes.sub
     }))      
     .catch(() => setUser(null));
    
    Auth.currentUserCredentials()
     .then(identity => setIdentity(identity.identityId))
     .catch(() => setIdentity(null));
  }, []);
  // make sure other components can read this value
  return (
    <UserContext.Provider value={{user, identity, login, logout}}>
      {props.children}
    </UserContext.Provider>
  )
};

export const useUser = () => {  
  const context = React.useContext(UserContext);
  if(context === undefined) {    
    throw new Error('`useUser` must be within a `UserProvider`');  
  }  
  return context;
};
