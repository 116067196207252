import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';


const configSource = require(`../../../clusterConfig.${process.env.NODE_ENV}.json`);


Amplify.configure(awsconfig);

const Api = new HttpLink({
  uri: configSource.AWS_GRAPHQL_ENDPOINT,
  fetch,
})

const setAuthorizationLink = setContext(request => {
  return new Promise((success, fail) => {
    Auth.currentSession().then(data => {
      console.log(data);
      success(
        { headers: { authorization: data.getAccessToken().getJwtToken() } }
      )
  });
  });
});

export const client = new ApolloClient({
  link: setAuthorizationLink.concat(Api),
  cache: new InMemoryCache()
});